<template>
  <div
    class="z-30 bg-white shadow top-5 left-5 absolute grid grid-cols-3 pl-6 pr-4 pt-5 grid-rows-layout pb-8"
    style="width: 450px"
  >
    <div
      class="col-start-1 col-end-3 font-bold text-xl text-darkblue flex justify-between mb-4"
    >
      <span class="font-SourceSansPro">
        {{ $t('routesTrips.trip.createGeozone.title') }}
      </span>
    </div>
    <div class="flex justify-end col-start-3 items-center">
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close'), $store.commit('geozones/END_DRAWING')"
      />
    </div>
    <div
      class="flex justify-end col-start-1 col-end-4 border-b border-divider pb-6 mt-6"
    >
      <skif-button
        variant="normal"
        @click="$emit('close'), $store.commit('geozones/END_DRAWING')"
        type="button"
      >
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button
        class="ml-6"
        @click="
          save()
          $emit('my-done')
          $store.commit('geozones/END_DRAWING')
        "
        type="button"
      >
        {{ $t('add') }}
      </skif-button>
    </div>
    <span
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3"
    >
      {{ $t('routesTrips.trip.createGeozone.figure') }}
    </span>
    <button
      class="mt-2 col-start-1 col-end-3 h-9 border focus_outline-none rounded focus_border-darkblue transition-colors duration-200 font-bold text-sm text-someGrey mb-5"
      type="button"
      style="width: 300px"
      @click="
        isIconShowen = false
        isTypeShowen = !isTypeShowen
      "
    >
      <div class="flex items-center justify-between px-3">
        <span>{{ getGeozoneType(geozone.type.key) }}</span>
        <svg
          class="duration-300"
          :style="[
            isTypeShowen
              ? { transform: 'rotateZ(-180deg)' }
              : { transform: 'rotateZ(0deg)' }
          ]"
          width="14"
          height="8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.266 0L7 4.823 1.734 0 0 1.589 7 8l7-6.411L12.266 0z"
            fill="#5477A9"
          />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="duration-300"
      leave-active-class="duration-300"
      enter-to-class="overflow-hidden max-h-130"
      leave-class="overflow-hidden max-h-130"
      leave-to-class="overflow-hidden max-h-0"
      enter-class="overflow-hidden max-h-0"
    >
      <ul
        class="col-start-1 col-end-3 border border-divider rounded-sm mt-1"
        style="width: 300px"
        v-show="isTypeShowen"
      >
        <li
          v-for="item in geozonesType"
          class="leading-8 h-8 block cursor-pointer px-3 font-bold text-sm hover_bg-reallylightblue"
          :key="item.key"
          @click="
            geozone.type.key = item.key
            $store.commit('geozones/SHAPE_CHANGE', geozone.type.key)
            geozone.width = 1
            isTypeShowen = false
          "
          :class="
            geozone.type.key === item.key ? 'text-textmain' : 'text-someGrey'
          "
        >
          {{ item.value }}
        </li>
      </ul>
    </transition>
    <span
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3"
    >
      {{ $t('routesTrips.trip.createGeozone.name') }}
    </span>
    <el-input
      class="col-start-1 col-end-3"
      style="width: 300px"
      v-model="geozone.name"
      :placeholder="$t('geozones.name')"
    />
    <span
      v-if="geozone.type.key === 'line'"
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3 mt-5"
    >
      {{ $t('routesTrips.route.createGeozone.width') }}
    </span>
    <el-input
      v-if="geozone.type.key === 'line'"
      class="col-start-1 col-end-3"
      style="width: 300px"
      type="number"
      v-model.number="geozone.width"
      @input="$store.commit('races/SET_WIDTH', geozone.width)"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CreateGeozones',
  data() {
    return {
      isActive: false,
      geozonesType: '',
      geozone: {
        name: 'Геозона',
        type: {
          key: '',
          type: 'geozone_type',
          value: ''
        },

        geometry: []
      },
      isTypeShowen: false,
      isIconShowen: false
    }
  },
  computed: {
    ...mapState('dictionary', {
      geozoneType: (state) => state.geozone_type,
      geozoneTypes: (state) => state.geo_icon,
      colors: (state) => state.color_code
    }),
    ...mapState('geozones', {
      currentGeozone: (state) => state.currentGeozone
    }),
    ...mapGetters('dictionary', ['getGeozoneType', 'getGeozoneIcon'])
  },
  watch: {
    currentGeozone(val) {
      switch (this.geozone.type.key) {
        case 'point':
          this.geozone.geometry = [val.latlng]
          break
        case 'circle':
          this.geozone.width = val.radius
          this.geozone.geometry = [val.latlng]
          break
        default:
          this.geozone.geometry = val.latlng
          break
      }
    }
  },

  methods: {
    clearFields() {
      this.geozone.name = 'Геозона'
      this.geozone.width = 1
    },

    save() {
      // this.geozoneType.forEach(geo=> {
      //   if(geozone.)
      // })
      // console.log('geozone to get radius', this.geozone)
      const component = this
      switch (this.geozone.type.key) {
        case 'polygon':
          this.geozone.id = this._uid
          this.geozone.geometry.length < 3
            ? this.$showError(this.$t('geozones.errors.min_dots_polygon'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[2].value) &&
              this.$store
                .dispatch('races/CREATE_GEOZONE', this.geozone)
                .then((_) => component.$emit('close'))

          break
        case 'circle':
          this.geozone.id = this._uid

          !this.geozone.width || this.geozone.geometry.length === 0
            ? this.$showError(this.$t('geozones.errors.draw_circle'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[0].value) &&
              this.$store
                .dispatch('races/CREATE_GEOZONE', this.geozone)
                .then((_) => component.$emit('close'))
          break
        // case 'line':
        //   this.geozone.id = this._uid
        //   this.geozone.geometry.length < 2
        //     ? this.$showError(this.$t('geozones.errors.min_dots_line'))
        //     : this.geozone.name === ''
        //     ? this.$showError(this.$t('geozones.errors.name'))
        //     : this.geozone.width < 1
        //     ? this.$showError(this.$t('geozones.errors.width'))
        //     : // : this.$store
        //       //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
        //       //     .then((_) => component.$emit('close'))
        //       (this.geozone.type.value = this.geozoneType[1].value) &&
        //       this.$store
        //         .dispatch('races/CREATE_GEOZONE', {
        //           ...this.geozone,
        //           arrival: '',
        //         })
        //         .then((_) => component.$emit('close'))
        //   break

        // case 'point':
        //   this.geozone.id = this._uid
        //   this.geozone.geometry.length < 1
        //     ? this.$showError('Поставьте маркере на крту!')
        //     : this.geozone.name === ''
        //     ? this.$showError(this.$t('geozones.errors.name'))
        //     : // : this.$store
        //       //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
        //       //     .then((_) => component.$emit('close'))
        //       (this.geozone.type.value = this.geozoneType[3].value) &&
        //       this.$store
        //         .dispatch('races/CREATE_GEOZONE', this.geozone)
        //         .then((_) => component.$emit('close'))
        //   break
        default:
          break
      }
      this.$store.commit('geozones/END_DRAWING')
    }
  },

  created() {
    this.geozone.type.key = this.geozoneType[2].key
    this.geozonesType = this.geozoneType.filter((geo) => {
      if (geo.key === 'circle') {
        return geo
      }
      if (geo.key === 'polygon') {
        return geo
      }
    })
  }
}
</script>
